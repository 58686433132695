import * as React from "react";
import Companies from "../components/companies/Companies";
import Cta from "../components/cta/Cta";
import Features from "../components/features/Features";
import Hero from "../components/hero/Hero";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import SEO from "../components/seo/SEO";
import Layout from "../components/layout/Layout";
import Testimonials from "../components/testimonials/Testimonials";
import { Script } from "gatsby";
export default function Home() {
  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>/meet 4 Slack - Start Google Meets directly from Slack</title>
        <meta
          name="description"
          content="Start Google Meetings directly from Slack using /meet in any channel, DM or group conversation."
        />
        <meta property="og:title" content="/meet for Slack" />
      </Helmet>
      <Hero />
      <div className="pt-8">
        <Testimonials />
      </div>
      <Features />
      <Companies />
      <Cta />
    </Layout>
  );
}
