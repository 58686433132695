import React from "react";
import { Helmet } from "react-helmet";

export default function Testimonials() {
  return (
    <>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto">
          <div class="senja-frame-embed" data-id="05ce1bee-5a85-4756-b677-0604206d220d"></div>
          <Helmet>
            <script
              defer
              type="text/javascript"
              src="https://widget.senja.io/embed/frame.js"
            ></script>
          </Helmet>
        </div>
      </div>
    </>
  );
}
