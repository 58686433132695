import * as React from "react";
import meet1 from "../../images/assets/meet1.png";
import meet3 from "../../images/assets/meet3.png";
import meet4 from "../../images/assets/meet4.png";

const features = [
  {
    name: "Unique meeting links",
    image: meet1,
    description:
      "Generate your Google Meet link in any channel, DM or group conversation just by typing /meet and optionally setting the meeting name.",
  },
  {
    name: "Always the right account",
    image: meet4,
    description:
      "Meet links always open with the correct account, which means you never have to switch your account manually again.",
  },
  {
    name: "Automatic Slack status",
    image: meet3,
    description:
      "Slack status gets auto updated to 📞 On a call for everyone who joins your meeting and resets automatically after 15 minutes.",
  },
];

export default function Features() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Start Google Meets from Slack.</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center rounded-sm  text-white">
                  {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                  <img
                    className="rounded-lg max-w-full shadow-xl ring-1 ring-black ring-opacity-5"
                    src={feature.image}
                    alt=""
                  />
                </div>

                <p className="mt-5 text-xl leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 text-lg text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
